<template>
  <Portal to="modalPortalTarget">
    <ModalStructure
      ref="modal"
      class="gotoModal"
      :showCloseButton="false"
      :showModal="showModal"
      :title="title"
      @close="handleModalClosed"
    >
      <template v-slot:body>
        <div
          class="body"
          @mousemove="captureInput"
        >
          <InputSearch
            :autofocus="true"
            :focus="true"
            placeholder="Go-To (⌘/Ctrl+K) ..."
            :value="search"
            @input="onInput"
          />
          <div class="searchResults">
            <div
              v-for="(type, typeIndex) in nonEmptyResultTypes"
              :key="typeIndex"
            >
              <div
                class="header"
                :data-test-id="$testId('header-' + dashed(type.title))"
              >
                {{ type.title }}
              </div>
              <ul>
                <li
                  v-for="item in type.data.slice(0, type.maxVisible)"
                  :key="item.id"
                  :class="shouldHighlight(item) ? 'highlightedResult' : null"
                  :data-test-id="$testId('result-' + dashed(type.title))"
                  @click="onLinkClick(type, item)"
                  @mouseover="onMouseOver(item)"
                >
                  <div>
                    <component
                      :is="item.imageComponent"
                      class="icon"
                    />
                    <div
                      class="title"
                    >
                      {{ item.title }}
                    </div>
                    <span
                      v-if="item.subtitle"
                      class="subtitle"
                    >
                      - {{ item.subtitle }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div
          class="footer"
          :data-test-id="$testId('footer')"
        >
          Not finding what you need?
          <a
            :href="supportHref"
            target="_blank"
            @click="onSupportFooterClicked"
          >
            Search our Knowledge Base!
          </a>
        </div>
      </template>
    </ModalStructure>
  </Portal>
</template>

<script>
import {
  HeadingLarge,
  Icon,
  InputSearch,
  Link,
  ModalStructure,
  ParagraphBody,
  StatusBadge,
} from '@jumpcloud/ui-components';
import { UiEvents } from '@jumpcloud/ui-events-vue';
import { cloneDeep } from 'lodash';
import { featuresSearch } from './features';
import LocalStorageService from '../../utils/LocalStorageService';
import iconSupport from '@/img/icons/nav/icon-nav-support.svg';

const goToRecentSelections = 'jcGoToRecentSelections';
const maxRecentClickTracked = 10;

const maxRecordedInputs = 10;

export default {
  name: 'GoToModal',

  components: {
    HeadingLarge,
    Icon,
    InputSearch,
    Link,
    ModalStructure,
    ParagraphBody,
    StatusBadge,
  },

  LocalStorageService,

  props: {
    showModal: Boolean,
  },

  data: () => ({
    featuresData: [],
    lastCapturedInput: '',
    numRecordedInputs: 0,
    recentClicks: [],
    recentsData: [],
    search: '',
    selectedIndex: 0,
    visibleResultCount: 0,
  }),

  computed: {
    featuresType() {
      return {
        title: 'Pages',
        data: this.featuresData,
        maxVisible: 10,
      };
    },

    kbResults() {
      if (this.featuresData?.length === 0) {
        return [{
          title: this.search,
          subtitle: '',
          keys: '',
          imageComponent: iconSupport,
          externalUrl: this.supportHref,
        }];
      }

      return [];
    },

    knowledgeBaseType() {
      return {
        title: 'Search Knowledge Base',
        data: this.kbResults,
        maxVisible: 1,
      };
    },

    nonEmptyResultTypes() {
      return this.resultTypes.filter((e) => e.data?.length > 0);
    },

    recentType() {
      return {
        title: 'Recent',
        data: this.recentsData,
        maxVisible: 3,
      };
    },

    resultTypes() {
      return [
        this.recentType,
        this.featuresType,
        this.knowledgeBaseType,
      ];
    },

    supportHref() {
      return this.search === ''
        ? 'https://jumpcloud.com/support'
        : `https://jumpcloud.com/support/search?search=${encodeURI(this.search)}`;
    },

    title() {
      return 'Go To ...  (ctrl + k)';
    },
  },

  mounted() {
    this.onInput(this.search);
  },

  created() {
    this.registerKeyDown();

    const { getItem } = this.$options.LocalStorageService;
    this.recentClicks = JSON.parse(getItem(goToRecentSelections)) || [];
  },

  destroyed() {
    this.unregisterKeyDown();
  },

  methods: {
    addRecent(item) {
      const id = item.title + item.subtitle;

      this.recentClicks = this.recentClicks.filter(t => t !== id);
      this.recentClicks.unshift(id);
      this.recentClicks.splice(maxRecentClickTracked);

      this.updateVisibleResults(this.featuresData);

      const { setItem } = this.$options.LocalStorageService;
      setItem(goToRecentSelections, JSON.stringify(this.recentClicks));
    },

    captureInput() {
      // No need to record if the input is a substring of (or equal to)
      // our most recent capture
      if (this.lastCapturedInput.startsWith(this.search)) {
        return;
      }

      // Just in case this blows up segment events, we set a max for safety
      if (this.numRecordedInputs >= maxRecordedInputs) {
        return;
      }

      this.lastCapturedInput = this.search;
      this.numRecordedInputs += 1;

      UiEvents.triggerInputEntered({
        description: 'GoTo modal input entered',
        displayLogic: this.search,
        page: 'GoTo Modal',
        section: 'input',
        region: this.numRecordedInputs.toString(),
        value: this.featuresData?.length.toString(),
      });
    },

    dashed(text) {
      return text.replaceAll(' ', '-');
    },

    filterResultsForFeatureFlags(results) {
      return results.filter((e) => this.shouldIncludeResult(e.featureFlag));
    },

    handleModalClosed(fromClick) {
      // If the modal is closing for any reason other than a click, we want to
      // record that they navigated away without clicking.
      if (!fromClick) {
        UiEvents.triggerButtonClicked({
          description: 'GoTo modal exited without link click',
          displayLogic: this.search,
          page: 'GoTo Modal',
          section: 'n/a',
          text: '',
        });
      }

      this.$emit('update:showModal', false);
      this.selectedIndex = 0;
    },

    lookupByIndex(desiredIndex) {
      // Search through all the data types (recent, pages) in the order
      // they are displayed to figure out which item is at the provided index
      for (const resultType of this.resultTypes) {
        const found = resultType.data?.find(result => result.visibleIndex === desiredIndex);

        if (found !== undefined) {
          return {
            item: found,
            type: resultType,
          };
        }
      }

      return null;
    },

    // matchingRecents collects all recent click entries that actually
    // match a search result (i.e. the search term is returning the
    // recent entry).
    matchingRecents(featuresData) {
      const matching = [];

      this.recentClicks.forEach(recentId => {
        const result = featuresData.find((item) => recentId === item.title + item.subtitle);
        if (result !== undefined) {
          matching.push(cloneDeep(result));
        }
      });

      return matching;
    },

    onInput(input) {
      this.search = input;
      let featuresData = featuresSearch(this.search);
      featuresData = this.filterResultsForFeatureFlags(featuresData);
      this.selectedIndex = 0;

      this.updateVisibleResults(featuresData);
    },

    onKeyDown(event) {
      // Arrow up
      if (event.keyCode === 38) {
        event.preventDefault();
        this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
      } else if (event.keyCode === 40) { // Arrow down
        event.preventDefault();
        this.selectedIndex = Math.min(this.selectedIndex + 1, this.visibleResultCount - 1);
      } else if (event.keyCode === 13) { // Enter key
        const currentItem = this.lookupByIndex(this.selectedIndex);
        this.onLinkClick(currentItem.type, currentItem.item);
      } else if (event.keyCode === 27) { // Escape key
        this.handleModalClosed();
      } else if (event.key === 'Backspace') {
        // If they are pressing backspace then it's possible they didn't find
        // what they were looking for, so let's capture this search string at
        // its fullest.
        this.captureInput();
      }
    },

    onLinkClick(type, item) {
      if (item.externalUrl) {
        // Slice off the leading # when calling programmatically
        window.open(item.externalUrl, '_blank');
      } else {
        // Slice off the leading # when calling programmatically
        this.$router.push(item.url);

        this.handleModalClosed(true);

        // Add this last so the UI doesn't change until after it is closed
        this.addRecent(item);
      }

      UiEvents.triggerLinkClicked({
        description: 'GoTo result clicked',
        displayLogic: this.search,
        page: 'GoTo Modal',
        section: 'results',
        region: type.title,
        text: `${item.title} - ${item.subtitle}`,
        toUrl: `${item.url || ''}${item.externalUrl || ''}`,
      });
    },

    onMouseOver(item) {
      this.selectedIndex = item.visibleIndex;
    },

    onSupportFooterClicked() {
      UiEvents.triggerLinkClicked({
        description: 'GoTo support footer clicked',
        displayLogic: this.search,
        page: 'GoTo Modal',
        section: 'footer',
        region: 'footer',
        toUrl: this.supprtHref,
      });
    },

    registerKeyDown() {
      window.addEventListener('keydown', this.onKeyDown, true);
    },

    shouldHighlight(item) {
      return item.visibleIndex === this.selectedIndex;
    },

    shouldIncludeResult(featureFlag) {
      if (!featureFlag) {
        return true;
      }

      const hasFlag = this.$hasFeatureFlag(featureFlag);

      return featureFlag.startsWith('!') === !hasFlag;
    },

    unregisterKeyDown() {
      window.removeEventListener('keydown', this.onKeyDown, true);
    },

    updateVisibleResults(featuresData) {
      // Update recent data BEFORE pruning down featuresData to make sure
      // recent entries for non-visible results are shown too
      this.recentsData = this.matchingRecents(featuresData).slice(0, this.recentType.maxVisible);
      this.featuresData = featuresData.slice(0, this.featuresType.maxVisible);

      // Now go through and assign a "visibleIndex" to each entry for easy arrow up/down navigation
      let totalIndex = 0;
      this.resultTypes?.forEach(resultType => {
        resultType.data?.forEach((result, index, data) => {
          // eslint-disable-next-line no-param-reassign
          data[index].visibleIndex = totalIndex;
          totalIndex += 1;
        });
      });
      this.visibleResultCount = totalIndex;
    },
  },
};
</script>

<style scoped>
.body /deep/ input {
  font-size: var(--jcBodyLarge);
}

.footer {
  font-size: var(--jcBody);
}

.gotoModal /deep/ div[class*="ModalContainer__modal"] {
  margin-top: 60px;
}

.gotoModal {
  --modal-width: 500px;
}

.header {
  background: var(--jc-background);
  font-size: var(--jcHeadingSmall);
  font-weight: bold;
  margin: 0;
  margin-top: var(--jc-spacer);
  padding: var(--jc-spacer-small);
}

.highlightedResult {
  background: var(--jc-color-informational-surface);
  cursor: pointer;
}

.icon.icon {
  fill: currentColor;
  height: 1.5em;
  margin-left: var(--jc-spacer-small);
  margin-right: var(--jc-spacer);
  vertical-align: middle;
  width: 1.5em;
}

li {
  padding-bottom: var(--jc-spacer-x-small);
  padding-top: var(--jc-spacer-x-small);
}

.searchResults {
  padding: var(--jc-spacer-small);
  width: 100%;
}

.subtitle {
  font-size: var(--jcBody);
}

.title {
  display: inline-block;
  font-size: var(--jcHeadingSmall);
  font-weight: bold;
  vertical-align: middle;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
